'use strict'

document.addEventListener('DOMContentLoaded', function(e){
    AddTravelers();

    document.querySelector('#sltFlightType').addEventListener('change', initDateRangePicker);

    $("#dateRangeSearchFlight").daterangepicker({
        singleDatePicker: true,
        locale: {
            format: "DD-MM-YYYY",
            cancelLabel: "Borrar",
        },
        minDate: moment(),
        startDate: moment().startOf("hour"),
        orientation: "top"
    });
    
    $('#dateRangeSearchFlight').on('cancel.daterangepicker', function(ev, picker) {
    //do something, like clearing an input
    $("#dateRangeSearchFlight").val("");
    });
});



//MÉTODOS

$("#form-search-flights").submit(function (event) {
    loadingGifFunction();
});

function AddTravelers(){

    const FlightTravelers = document.querySelector('#FlightTravelers');
    const adults = document.querySelector('#numAdults');
    const kids = document.querySelector('#numKids');
    const babys = document.querySelector('#numBabys');
    const botones = [adults, kids, babys];
    const hddTravelers = ['adults', 'childs', 'babys'];

    //indices de cada elemento de arrgelo 'botones'
    /*
        0: btn remove
        1: número de pasajeros
        2: btn add
    */

    disabledButtonTraveler(botones);

    //evento para agregar nuevo pasajero
    botones.forEach(function(item, index){
        item.children[2].addEventListener('click', function(e){
            e.preventDefault();
            if(NumTravelers() < 9){
                item.children[1].textContent = Number(item.children[1].textContent) + 1;
                disabledButtonTraveler(botones);
                FlightTravelers.previousElementSibling.textContent = `${NumTravelers()} Viajeros`;
                document.querySelector(`#input-flight-${hddTravelers[index]}`).value = item.children[1].textContent;
            }
        });
    });

    // evento para eliminar un pasajero
    botones.forEach(function(item, index){
        item.children[0].addEventListener('click', function(e){
            e.preventDefault();
            if(Number(item.children[1].textContent) >= 1){
                item.children[1].textContent = Number(item.children[1].textContent) - 1;
                disabledButtonTraveler(botones);
                FlightTravelers.previousElementSibling.textContent = `${NumTravelers()} Viajeros`;
                document.querySelector(`#input-flight-${hddTravelers[index]}`).value = item.children[1].textContent;

            }
        });
    });
    

}

function NumTravelers(){
    const containerTravelers = document.querySelectorAll('#FlightTravelers p');
    let travelers = 0;

    containerTravelers.forEach(function(item){
        travelers += Number(item.textContent);
    });

    return travelers;
}

function disabledButtonTraveler(arrayButtons){
        //indices de cada elemento de arreglo 'botones'
    /*
        0: btn remove
        1: número de pasajeros
        2: btn add
    */

    let numTravelers = NumTravelers();
    if(numTravelers == 9){
        arrayButtons.forEach(function(item){
            item.children[0].disabled = false;
            item.children[2].disabled = true;
            item.children[0].classList.add('bg-primary');
            item.children[0].classList.remove('bg-secondary');
            item.children[2].classList.remove('bg-primary');
            item.children[2].classList.add('bg-secondary');

        });

    }else{
        arrayButtons.forEach(function(item){
            if(Number(item.children[1].textContent) == 0){
                item.children[2].disabled = false;
                item.children[0].disabled = true;
                item.children[0].classList.add('bg-secondary');
                item.children[0].classList.remove('bg-primary');
                item.children[2].classList.add('bg-primary');
                item.children[2].classList.remove('bg-secondary');
            }
            else if(Number(item.children[1].textContent) > 0 && Number(item.children[1].textContent) < 9){
                item.children[2].disabled = false;
                item.children[0].disabled = false;
                item.children[0].classList.remove('bg-secondary');
                item.children[2].classList.remove('bg-secondary');
                item.children[0].classList.add('bg-primary');
                item.children[2].classList.add('bg-primary');
            }

        });
    }

}

// Función para inicializar el dateRangePicker según el modo
function initDateRangePicker(e) {
    if (e.target.value === 'One Way') { //date single
        $("#dateRangeSearchFlight").daterangepicker({
            singleDatePicker: true,
            locale: {
                format: "DD-MM-YYYY",
                cancelLabel: "Borrar",
            },
            minDate: moment(),
            startDate: moment().startOf("hour"),
            orientation: "top"
        });
    } else if (e.target.value === 'Round-Trip') { //date range
        $("#dateRangeSearchFlight").daterangepicker({
            locale: {
                format: "DD-MM-YYYY",
                cancelLabel: "Borrar",
            },
            minDate: moment(),
            startDate: moment().startOf("hour"),
            endDate: moment().startOf("hour").add(168, "hour"),
            orientation: "top"
        });
    }
}